.App {
  text-align: center;
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: #330616;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.equiped {
  display: grid;
  grid-template-columns: repeat(12, 100px);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.equiped0{
  grid-column: 2;
  grid-row: 1;
}

.equiped1{
  grid-column: 2;
  grid-row: 2;
}

.equiped2{
  grid-column: 1;
  grid-row: 1;
}

.equiped3{
  grid-column: 1;
  grid-row: 2;
}

.equiped4{
  grid-column: 3;
  grid-row: 1;
}

.equiped5{
  grid-column: 3;
  grid-row: 2;
}

.equiped6{
  grid-column: 4;
  grid-row: 2;
}

.equiped7{
  grid-column: 4;
  grid-row: 1;
}

.equiped8{
  grid-column: 1;
  grid-row: 3;
}

.empty-slot {
  width: 100%; 
  height: 100%; 
  background-color: transparent; 
}

.caracter{
  grid-column: 6/8;
  grid-row: 1/3;
  width: 100%;
}

.lvl{
  grid-column: 6/8;
  grid-row: 4;
  width: 100%;
}

.force{
  grid-column: 8/11;
  grid-row: 2;
  width: 100%;
}

.dexterite{
  grid-column: 11/13;
  grid-row: 1;
  width: 100%;
}

.endurance{
  grid-column: 8/11;
  grid-row: 1;
  width: 100%;
}

.chance{
  grid-column: 11/13;
  grid-row: 2;
  width: 100%;
}

.armure{
  grid-column: 11/13;
  grid-row: 3;
  width: 100%;
}

.inventory {
  display: grid;
  grid-template-columns: repeat(12, 100px);
  gap: 10px;
  grid-auto-rows: minmax(100px, 100px);
}

.img {
  cursor: pointer;
  transition: all .2s ease-in-out;
  width: 64px;
  height: 64px;
}

.transparent-image {
  cursor: pointer;
  transition: all .2s ease-in-out;
  width: 64px;
  height: 64px;
  opacity: 0.25;
}

.img:hover {
  transform: scale(1.1);
}

.justify{
  text-align-last:justify;
}

.justify-flex{
  display: flex;
  justify-content: space-between;
}

.strongerStat {
  color: green; /* Couleur verte pour les statistiques plus fortes */
}

.weakerStat {
  color: red; /* Couleur rouge pour les statistiques plus faibles */
}


.selected {
  position: relative;
}

.item.selected {
  filter: brightness(25%);
  transition: filter 0.3s ease-in-out;
}


.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.item-overlay button {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px; 
  margin: 0 5px;
  cursor: pointer;
  z-index: 2;
  background-size: cover;
}

.item-overlay button.add {
  background-image: url('./images/icons/add.png');
  background-color: #990030;

}

.item-overlay button.delete {
  background-image: url('./images/icons/delete.png'); 
  background-color: #690525;

}

.item-overlay button.add:hover {
  background-color: #bb003b;
}

.item-overlay button.delete:hover {
  background-color: #80062d;
}

.leaderboard {
  border: none;
  width: 100px;
  height: 100px;
  border-radius: 10px; 
  cursor: pointer;
  background-size: cover;
  background-image: url('./images/icons/leaderboard.png');
  background-color: #690525;
  border: 5px solid;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 2; 
}

.profile {
  border: none;
  width: 100px;
  height: 100px;
  border-radius: 10px; 
  cursor: pointer;
  background-size: cover;
  background-image: url('./images/icons/profile.png');
  background-color: #690525;
  border: 5px solid;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 2; 
}

@media screen and (max-width: 768px) {
  .equiped {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .equiped > span {
    margin-bottom: 10px;
  }

  .lvl{
    padding-bottom: 25px;
  }

  .caracter {
    display: none;
  }
  
  .inventory {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
  }

  .leaderboard {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 5px; 
    cursor: pointer;
    background-size: cover;
    background-image: url('./images/icons/leaderboard.png');
    background-color: #690525;
    border: 2px solid;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2; 
  }
}


.leaderboard-container {
  background-color: #690525;
  width: 865px;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.leaderboard-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.player {
  background-color: #530d26;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player:nth-child(even) {
  background-color: #721938;
}

.player span {
  font-size: 16px;
}

.player span:first-child {
  color: rgb(187, 187, 187);
}

.player span:last-child {
  color: rgb(180, 180, 180);
}


@media screen and (max-width: 768px) {
  .leaderboard-container {
    padding: 10px;
  }

  .player {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .player span {
    font-size: 14px;
  }

  .player span:first-child {
    margin-bottom: 5px;
  }

  h1 {
    font-size: 24px; 
  }

  h2 {
    font-size: 16px; 
  }
}

a.twitch-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6441a5; 
  color: #fff; 
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

a.twitch-button:hover {
  background-color: #4b367c; 
}


.twitch-icon {
  vertical-align: middle;
  margin-right: 5px; 
}

.player1-bar {
  padding: 5px;
  position: absolute;
  top: 100px;
  left: 15px;
}

.player2-bar {
  padding: 5px;
  position: absolute;
  top: 100px;
  left: 315px;
}

.health-bar {
  height: 20px;
  background-color: green;
}

.gif-player-name {
  font-size: 30px;
  color: black;
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: white; /* Pour les navigateurs WebKit comme Chrome et Safari */
}